import { useDispatch, useSelector } from 'react-redux';
import Controls from '../../controls/Controls';

import {
    checkUsernameAvailabilityAsync,
    resetStatus,
    selectAuthState,
    selectUserName,
    setUser,
} from '../auth/authSlice';
import { useForm } from '../../controls/useForm';
import {
    Box,
    Button,
    CircularProgress,
    Fade,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import MuiButton from '../../controls/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SignUp from '../auth/signup';
import { motion } from 'framer-motion';
import logo from './../../images/Logo.png';
import daria from './../../images/art/DariaInTheSky.png';
import thinking from './../../images/art/thinking.png';
import darwin from './../../images/art/mini-darwin.png';
import estrogen from './../../images/art/estrogen.png';
import cloud from './../../images/large/cloud.png';
import bg from './../../images/large/landing-page-concept.png';
import foreground from './../../images/large/fg-1.png';
import foreground2 from './../../images/large/fg-2.png';
import { useEffect, useRef, useState } from 'react';
import './temp-landing.scss';
import { transform } from 'typescript';

export default function TempLandingPage() {
    const card = useRef<HTMLDivElement>(null);
    const thinkingg = useRef<HTMLDivElement>(null);
    const dariar = useRef<HTMLImageElement>(null);
    const estrogenn = useRef<HTMLImageElement>(null);
    const darwinn = useRef<HTMLImageElement>(null);
    const background = useRef<HTMLImageElement>(null);

    const motionMatchMedia = window.matchMedia(
        '(prefers-reduced-motion)',
    );

    const comingSoon = [
        'C',
        'o',
        'm',
        'i',
        'n',
        'g',
        ' ',
        ' ',

        's',
        'o',
        'o',
        'n',
    ];
    const [bgLoaded, setBgLoaded] = useState(false);
    const [xValue, setX_value] = useState(0);
    const [yValue, setY_value] = useState(0);

    const setXY = (e: any) => {
        setX_value(e.clientX - window.innerWidth / 2);
        setY_value(e.clientY - window.innerHeight / 2);
        console.log(yValue, xValue);
    };

    const handleHover = (e: any, element: any, threshold: number) => {
        if (!motionMatchMedia.matches) {
            const { clientX, clientY, currentTarget } = e;
            const {
                clientWidth,
                clientHeight,
                offsetLeft,
                offsetTop,
            } = currentTarget;

            const horizontal = (clientX - offsetLeft) / clientWidth;
            const vertical = (clientY - offsetTop) / clientHeight;
            const rotateX = (
                threshold / 2 -
                horizontal * threshold
            ).toFixed(2);
            const rotateY = (
                vertical * threshold -
                threshold / 2
            ).toFixed(2);

            if (element.current) {
                window.removeEventListener('mousemove', setXY);
                element.current.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
            }
        }
    };

    const resetFrame = (e: any) => {
        if (!motionMatchMedia.matches && card.current) {
            card.current.style.transform = `perspective(580px) rotateX(0deg) rotateY(-10deg)`;
            window.addEventListener('mousemove', setXY);
        }
    };

    const resetStyles = (e: any, element: any) => {
        if (!motionMatchMedia.matches && element.current) {
            element.current.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
            window.addEventListener('mousemove', setXY);
        }
    };

    const dispatch = useDispatch();

    const authState = useSelector(selectAuthState);
    const userName = useSelector(selectUserName);
    const [submitted, setSubmitted] = useState(false);
    const reservedUrl = 'artazium.com/';

    const initialValues = {
        username: '',
    };

    const validate = (fieldValues = values) => {
        let temp: any = { ...errors };

        if ('username' in fieldValues) {
            temp.username = fieldValues.username
                ? ''
                : 'Please type your username.';
        }

        setErrors({
            ...temp,
        });

        console.log('fieldValues == values', fieldValues == values);
        if (fieldValues == values)
            return Object.values(temp).every((x) => x == '');
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    }: any = useForm(initialValues, true, validate);

    useEffect(() => {
        window.addEventListener('mousemove', setXY);
        return () => window.removeEventListener('mousemove', setXY);
    }, []);

    useEffect(() => {
        values.username = '';
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (usernameAvailable()) {
                dispatch(
                    checkUsernameAvailabilityAsync(values.username),
                );
            }

            setSubmitted(true);
            // resetForm();
        }
    };

    const usernameAvailable = () => {
        // console.log('submitted:', submitted);
        // console.log(
        //     'available:',
        //     authState.unavailableUsernames,
        //     authState.unavailableUsernames.find(
        //         (user) => user.username === values.username,
        //     ) === undefined,
        // );
        return (
            authState.unavailableUsernames.find(
                (user) => user.username === values.username,
            ) === undefined
        );
    };

    return (
        <div className="preload">
            {!bgLoaded ? (
                <div className="text-center pt-16">
                    <motion.img
                        src={logo}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 3,
                            delay: 0.5,
                            type: 'spring',
                        }}
                        alt="logo"
                        className="title logo"
                    />
                    <img
                        className="preload-invisible"
                        src={bg}
                        onLoad={() => setBgLoaded(true)}></img>
                </div>
            ) : (
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 2, type: 'spring' }}
                    variants={{
                        visible: { opacity: 1 },
                        hidden: { opacity: 0 },
                    }}
                    className="temp-landing-page">
                    <Grid
                        className="grid"
                        container
                        direction="column"
                        alignItems="center">
                        <Box className="text-center title">
                            <img
                                src={logo}
                                alt="logo"
                                className="logo"
                            />

                            <motion.p
                                className="white-text superlight m-0"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    duration: 3,
                                    delay: 0.5,
                                    type: 'spring',
                                }}>
                                YOUR OWN PREMIUM ART GALLERY
                            </motion.p>
                            <div className="white-text text-shadow">
                                {comingSoon.map((el, i) => (
                                    <motion.span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{
                                            duration: 0.25,
                                            delay: 3 + i / 8,
                                        }}
                                        key={i}>
                                        {el}
                                    </motion.span>
                                ))}
                            </div>
                        </Box>

                        {authState.status !== 'success' && (
                            <motion.h1
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    duration: 4,
                                    delay: 5,
                                    type: 'spring',
                                }}
                                className="header white-text">
                                Claim your username:
                            </motion.h1>
                        )}

                        <motion.div
                            className="form-box full-width"
                            initial={{ y: 1500 }}
                            animate={{ y: 0 }}
                            transition={{
                                duration: 4,
                                delay: 2,
                                type: 'spring',
                            }}>
                            {authState.status === 'idle' && (
                                <>
                                    {userName && submitted && (
                                        <MuiButton
                                            className="full-width back-button"
                                            variant="outlined"
                                            startIcon={
                                                <ArrowBackIcon />
                                            }
                                            text="Try a different username"
                                            onClick={() => {
                                                setSubmitted(false);
                                                dispatch(setUser(''));
                                            }}
                                        />
                                    )}
                                    <div className="d-flex justify-center col align-center wrap">
                                        {submitted && (
                                            <Box
                                                className="mt-8"
                                                sx={{
                                                    color: userName
                                                        ? 'green'
                                                        : 'inherit',
                                                }}>
                                                {userName
                                                    ? reservedUrl +
                                                      userName +
                                                      ' is available!'
                                                    : !usernameAvailable() && (
                                                          <>
                                                              <span className="error">
                                                                  {values.username +
                                                                      ' '}
                                                              </span>
                                                              is not
                                                              available.
                                                              Please
                                                              try{' '}
                                                              <span className="no-wrap">
                                                                  something
                                                                  else.
                                                              </span>
                                                          </>
                                                      )}
                                            </Box>
                                        )}

                                        {!userName && (
                                            <Box className="mt-16">
                                                {usernameAvailable() && (
                                                    <h1 className="header-mobile">
                                                        Claim your
                                                        username:
                                                    </h1>
                                                )}
                                                <div className="d-flex text-center">
                                                    <div className="bold mt-8">
                                                        {reservedUrl}
                                                    </div>
                                                    <Controls.Input
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        autoComplete="off"
                                                        label="your_username"
                                                        name="username"
                                                        value={
                                                            values.username
                                                        }
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        error={
                                                            errors.username
                                                        }
                                                        size="small"
                                                    />
                                                </div>

                                                <MuiButton
                                                    sx={{
                                                        mt: 2,
                                                    }}
                                                    className="full-width"
                                                    type="submit"
                                                    text="Check availability"
                                                    color="success"
                                                    onClick={
                                                        handleSubmit
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </div>
                                </>
                            )}

                            {authState.status !== 'error' && (
                                <Box className="d-flex col justify-center align-center">
                                    <Fade
                                        in={
                                            authState.status ===
                                                'loading' ||
                                            authState.status ===
                                                'loading_username_check'
                                        }
                                        style={{
                                            transitionDelay:
                                                authState.status ===
                                                'idle'
                                                    ? '800ms'
                                                    : '0ms',
                                        }}
                                        unmountOnExit>
                                        <CircularProgress
                                            sx={{ m: 5 }}
                                        />
                                    </Fade>

                                    {authState.status ===
                                        'loading_username_check' && (
                                        <Typography className="full-width">
                                            Checking availability of{' '}
                                            <b>{values.username}</b>
                                            ...
                                        </Typography>
                                    )}

                                    {authState.status ===
                                        'loading' && (
                                        <Typography className="full-width">
                                            Creating an account with
                                            the username{' '}
                                            <b>{userName}</b>...
                                        </Typography>
                                    )}
                                </Box>
                            )}

                            {userName &&
                                authState.status === 'idle' && (
                                    <>
                                        <p className="text-center p-20 m-0">
                                            Please sign up to reserve{' '}
                                            <b>{userName}</b>
                                        </p>
                                        <SignUp />
                                    </>
                                )}

                            {authState.status === 'error' && (
                                <>
                                    <MuiButton
                                        className="full-width back-button"
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon />}
                                        text={
                                            authState.error
                                                .message ===
                                            'User already registered'
                                                ? 'Try a different email'
                                                : 'Try again'
                                        }
                                        onClick={() => {
                                            dispatch(resetStatus());
                                        }}
                                    />

                                    <p className="text-center mt-16 error">
                                        {authState.error.name !==
                                        'AuthApiError'
                                            ? `Ehhh.. Something didn't go right
                                    on our end. Please give it another
                                    try later.`
                                            : authState.error
                                                  .message ===
                                              'User already registered'
                                            ? 'Email is already registered.'
                                            : authState.error
                                                  .message + '.'}
                                    </p>
                                </>
                            )}

                            {authState.status === 'success' && (
                                <p className="success text-center">
                                    Username{' '}
                                    <span className="green bold">
                                        {userName}
                                    </span>{' '}
                                    was successfully reserved! <br />{' '}
                                    A confirmation link was sent to
                                    your email.
                                    <br /> <br />
                                    Thank you!
                                </p>
                            )}
                        </motion.div>
                    </Grid>

                    <img
                        className="bg"
                        ref={background}
                        style={{
                            transform: `translateX(calc(-50% + ${
                                -xValue * 0.1
                            }px)) translateY(calc(-50% + ${
                                -yValue * 0.1
                            }px))`,
                        }}
                        alt="daria"
                        src={bg}></img>

                    <motion.div
                        ref={dariar}
                        className="daria"
                        initial={{ opacity: 0, y: -500 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 4,
                            delay: 0,
                            type: 'spring',
                        }}>
                        <img
                            onMouseLeave={(ev) =>
                                resetStyles(ev, dariar)
                            }
                            onMouseMove={(ev) => {
                                return window.innerWidth > 575
                                    ? handleHover(ev, dariar, 4)
                                    : null;
                            }}
                            style={{
                                transform: `perspective(400px) translateX(${
                                    -xValue * 0.03
                                }px) translateY(${
                                    -yValue * 0.03
                                }px) rotateX(0deg) rotateY(${
                                    window.innerWidth > 575 ? 5 : 12
                                }deg)`,
                            }}
                            alt="daria"
                            src={daria}></img>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: -600 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 9,
                            delay: 0,
                            type: 'spring',
                        }}
                        className="thinking">
                        <img
                            onMouseLeave={(ev) =>
                                resetStyles(ev, thinkingg)
                            }
                            onMouseMove={(ev) => {
                                return window.innerWidth > 575
                                    ? handleHover(ev, thinkingg, 4)
                                    : null;
                            }}
                            style={{
                                transform: `perspective(400px) translateX(${
                                    -xValue * 0.03
                                }px) translateY(${
                                    -20 + -yValue * 0.03
                                }px) rotateX(0deg) rotateY(-17deg)`,
                            }}
                            alt="thinking"
                            src={thinking}></img>
                    </motion.div>

                    <motion.div
                        className="estrogen"
                        ref={estrogenn}
                        initial={{ y: -1500 }}
                        animate={{ y: 0 }}
                        transition={{
                            duration: 9,
                            delay: 2,
                            type: 'spring',
                        }}>
                        <img
                            src={estrogen}
                            style={{
                                transform: `perspective(380px) translateX(${
                                    -xValue * 0.03
                                }px) translateY(${
                                    -yValue * 0.03
                                }px) rotateX(0deg) rotateY(${
                                    window.innerWidth > 575 ? 12 : 335
                                }deg)`,
                            }}
                            alt="estrogen"></img>
                    </motion.div>

                    {/* <motion.div
                className="estrogen2"
                ref={estrogenn}
                initial={{ y: -1500 }}
                animate={{ y: 0 }}
                transition={{
                    duration: 10,
                    delay: 2.4,
                    type: 'spring',
                }}>
                <img
                    src={estrogen}
                    style={{
                        transform: `perspective(380px) translateX(${
                            -xValue * 0.03
                        }px) translateY(${
                            -yValue * 0.03
                        }px) rotateX(0deg) rotateY(${
                            window.innerWidth > 575 ? 12 : 335
                        }deg)`,
                    }}
                    alt="estrogen"></img>
            </motion.div> */}

                    <motion.div
                        className="darwin"
                        ref={darwinn}
                        initial={{ y: -1500 }}
                        animate={{ y: 0 }}
                        transition={{
                            duration: 9,
                            delay: 1,
                            type: 'spring',
                        }}>
                        <img
                            src={darwin}
                            style={{
                                transform: `translateX(${
                                    -xValue * 0.08
                                }px) translateY(${-yValue * 0.08}px)`,
                            }}
                            alt="darwin"></img>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: -600 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 9,
                            delay: 1,
                            type: 'spring',
                        }}
                        className="container">
                        <article
                            className="card"
                            ref={card}
                            style={{
                                transform: `perspective(400px) translateX(${
                                    -xValue * 0.03
                                }px) translateY(${
                                    -20 + -yValue * 0.03
                                }px) rotateX(0deg) rotateY(-10deg)`,
                            }}
                            onMouseLeave={(ev) => resetFrame(ev)}
                            onMouseMove={(ev) =>
                                handleHover(ev, card, 4)
                            }>
                            <p className="content white-text">
                                {/* Tired of boring art marketplaces? ARTAZIUM
                        gives you the opprortunity to match the
                        quality of your online presence with the
                        uniqueness of your creativity. Our platform is
                        designed for artists by an artist. */}
                                {window.innerWidth > 575
                                    ? `Tired of boring online art marketplaces?
                        Elevate your creative journey with our premium
                        art gallery. Our platform is designed to showcase
                         a unique representation of your art and its scale.`
                                    : ''}
                            </p>
                        </article>
                    </motion.div>

                    <motion.img
                        initial={{ opacity: 0, y: 500 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 4,
                            delay: 1,
                            type: 'spring',
                        }}
                        className="cloud"
                        alt="cloud"
                        src={cloud}></motion.img>

                    <motion.div
                        className="fg"
                        initial={{ opacity: 0, y: 500 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 4,
                            delay: 1,
                            type: 'spring',
                        }}>
                        <img
                            style={{
                                transform: `translateX(${
                                    -xValue * 0.01
                                }px) translateY(${-yValue * 0.01}px)`,
                            }}
                            alt="hill-1"
                            src={foreground}></img>
                    </motion.div>

                    <motion.div
                        className="fg-2"
                        initial={{ opacity: 0, y: 500 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 4,
                            delay: 1,
                            type: 'spring',
                        }}>
                        <img
                            style={{
                                transform: `translateX(${
                                    -xValue * 0.02
                                }px) translateY(${-yValue * 0.02}px)`,
                            }}
                            alt="hill-2"
                            src={foreground2}></img>
                    </motion.div>
                </motion.div>
            )}
        </div>
    );
}
