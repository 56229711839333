import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import SignUp from './components/auth/signup';
import { useSelector } from 'react-redux';
import { PaletteMode } from '@mui/material';
import LandingPage from './components/landing-page';
import Art from './components/art/art';
import TempLandingPage from './components/temp-landing/temp-landing';
import { selectTheme } from './components/themeSlice';
import ThankYouPage from './components/temp-landing/thank-you';

function App() {
    const theme: PaletteMode = useSelector(selectTheme);
    const theTheme = createTheme({
        palette: {
            mode: theme,
        },
        typography: {
            // fontFamily: "'Roboto', sans-serif",
            // fontFamily: 'Gruppo',
            fontFamily: "'Exo 2', sans-serif",
        },
    });
    return (
        <ThemeProvider theme={theTheme}>
            <CssBaseline />

            <Routes>
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<TempLandingPage />} />
                <Route path="/thankyou" element={<ThankYouPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/canvas" element={<Art />} />
                {/* <Route path="/:id" element={<ProfilePage />} />
                <Route path="/:id/account" element={<AccountPage />} /> */}
            </Routes>
        </ThemeProvider>
    );
}

export default App;

//             <Routes>
//                 <Route path="/" element={<LandingPage />} />
//                 {/* <Route path="/" element={<HomePage />} /> */}
//                 <Route path="/:id" element={<ProfilePage />} />
//                 <Route path="art/create" element={<PostArtPage />} />
//                 <Route path="/signup" element={<SignUp />} />
//             </Routes>
